import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Text, useDisclosure, useToast, HStack, VStack, Button, Input, OrderedList, ListItem, Icon, Tooltip, Menu, MenuButton, MenuList, MenuItem, Link } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, ViewOffIcon, ViewIcon, PhoneIcon, EmailIcon, TimeIcon, WarningIcon } from '@chakra-ui/icons';
import { FaUser, FaEdit, FaInfo, FaRegEnvelope, FaSync } from 'react-icons/fa';
import BlueBox from './BlueBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useOrganizationClients } from '../contexts/OrganizationClientsContext';
import PropTypes from 'prop-types';

const GrayBox = ({ 
  title,
  subtitle,
  metadata = {},
  listItems = [],
  rows = [],
  transcriptId,
  client = [],
  id,
  type,
  scrollRef,
  onArchive,
  errorItems = [],
  correspondence = {},
  nonClientSpeakers = []
}) => {
  const toast = useToast();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [searchQuery, setSearchQuery] = useState('');
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);
  const [isHoveredTitle, setIsHoveredTitle] = useState(false);
  const { clients, loading: clientsLoading } = useOrganizationClients();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;

  const [stateTitle, setStateTitle] = useState(title);
  const [stateClient, setStateClient] = useState(client);
  const [stateItems, setStateItems] = useState(listItems);
  const [stateRows, setStateRows] = useState(rows);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  const [showClientSelection, setShowClientSelection] = useState(false);

  useEffect(() => {
    setStateTitle(title);
    setStateItems(listItems);
    setStateRows(rows);
    setStateCorrespondence(correspondence);
    setStateClient(client);
  }, [title, listItems, rows, correspondence, client]);

  const formatDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const getCorrespondenceStyle = () => {
    if (type === 'call') {
      return {
        icon: PhoneIcon,
        bg: 'blue.50',
        color: 'blue.500'
      };
    }
    return {
      icon: EmailIcon,
      bg: 'green.50',
      color: 'green.500'
    };
  };

  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(stateTitle);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Title cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-title/${id}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );
      setStateTitle(response.data.call.title);
      setIsEditingTitle(false);
      toast({
        title: "Title Updated",
        description: "The call title has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating title:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the title.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleMouseEnterTitle = () => setIsHoveredTitle(true);
  const handleMouseLeaveTitle = () => setIsHoveredTitle(false);
  const handleMouseEnter = () => setShowErrorTooltip(true);
  const handleMouseLeave = () => setShowErrorTooltip(false);

  const handleClientSelect = async (selectedClientId) => {
    const selectedClient = clients.find(client => client.id === selectedClientId);
    if (!selectedClient) {
      console.error('Selected client not found');
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-call-client/${id}`,
        { clientId: selectedClientId },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      setStateClient([selectedClient]);
      setStateCorrespondence(prev => ({
        ...prev,
        correspondenceType: null,
      }));

      toast({
        title: "Client Updated",
        description: "The client for this call has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error tagging client:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the client.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewTranscript = () => {
    if (scrollRef?.current) {
      sessionStorage.setItem('scrollPosition', scrollRef.current.scrollTop.toString());
    }
    navigate(`/transcripts/${transcriptId}`);
  };

  const handleViewEmail = () => {
    if (scrollRef?.current) {
      sessionStorage.setItem('scrollPosition', scrollRef.current.scrollTop.toString());
    }

    axios.get(`${serverUrl}/api/emails/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        if (response.data?.metadata?.webLink) {
          window.open(response.data.metadata.webLink, '_blank');
        } else {
          console.error('No web link available for this email');
        }
      })
      .catch(error => {
        console.error('Error fetching email:', error);
      });
  };

  const handleFollowUp = () => {
    navigate(`/follow-up/${type}/${id}`);
  };

  const handleResync = async () => {
    console.log('GrayBox Component: handleResync - Initiating resync process');
    try {
        console.log('GrayBox Component: handleResync - Resyncing all clients for the organization');
        const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        });

        if (response.data.message) {
            toast({
                title: "Resync Initiated",
                description: "All client data resync has been initiated. This may take a few moments.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        } else {
            throw new Error(response.data.message || "Failed to initiate resync");
        }
    } catch (error) {
        console.error("Error initiating resync:", error);
        toast({
            title: "Resync Failed",
            description: `Failed to initiate resync: ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    } finally {
        console.log('GrayBox Component: handleResync - Resync process completed');
    }
  };

  const handleArchiveCall = (id) => {
    console.log('Archive Call');
    axios.post(`${serverUrl}/api/calls/${id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchiveCall = (id) => {
    console.log('Unarchive Call');
    axios.post(`${serverUrl}/api/calls/${id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleArchiveEmail = (id) => {
    console.log('Archive Email');
    axios.post(`${serverUrl}/api/emails/${id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchiveEmail = (id) => {
    console.log('Unarchive Email');
    axios.post(`${serverUrl}/api/emails/${id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const NoClientBanner = () => (
    <HStack 
      spacing={2} 
      p={2} 
      bg="orange.50" 
      borderRadius="md" 
      border="1px solid" 
      borderColor="orange.200"
      mb={4}
    >
      <WarningIcon color="orange.400" />
      <Text fontSize="sm" color="orange.700">
        No client assigned - Actions are limited until a client is tagged
      </Text>
    </HStack>
  );

  // The original UTC time is stored in the title between || and ||.
  // This function extracts the time and formats it in the user's local timezone, to avoid timezone confusion between the server and client.
  const formatTitleWithDate = (title) => {
    if (!title) return '';
    
    // Check if title contains an ISO timestamp between ||
    const matches = title.match(/\|\|(.*?)\|\|/);
    if (!matches) return title;

    // Extract the parts
    const [fullMatch, isoTime] = matches;
    const baseTitle = title.replace(fullMatch, '').trim();
    
    // Format the date in user's local timezone
    const date = new Date(isoTime);
    const formattedDate = date.toLocaleString(undefined, {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    return `${baseTitle} on ${formattedDate}`;
  };

  const handleRetagClient = () => {
    setShowClientSelection(true);
  };

  return (
    <Box 
      bg="#F5F5F5" 
      p={4} 
      mt={4} 
      borderRadius={20} 
      position="relative" 
      border="1px" 
      borderColor={!stateClient?.length ? "orange.200" : "gray.200"}
      boxShadow={!stateClient?.length ? "0 0 0 1px rgba(251, 146, 60, 0.1)" : "sm"}
      transition="all 0.3s ease"
      _hover={{ 
        boxShadow: !stateClient?.length ? 
          "0 0 0 1px rgba(251, 146, 60, 0.2)" : 
          'md', 
        cursor: 'pointer' 
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onToggle();
        }
      }}
    >
      {!stateClient?.length && <NoClientBanner />}

      <VStack align="stretch" spacing={3}>
        <HStack spacing={4} align="flex-start">
          <Box 
            p={2} 
            bg={getCorrespondenceStyle().bg}
            borderRadius="md"
            color={getCorrespondenceStyle().color}
          >
            <Icon as={getCorrespondenceStyle().icon} boxSize={5} />
          </Box>

          <VStack align="start" spacing={2} flex={1}>
            <HStack w="100%" justify="space-between" align="center">
              {isEditingTitle ? (
                <VStack align="start" spacing={1} width="100%">
                  <Input
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    placeholder="Enter new title"
                  />
                  <HStack>
                    <Button size="sm" colorScheme="green" onClick={handleSaveTitle}>
                      Save
                    </Button>
                    <Button size="sm" colorScheme="red" onClick={handleCancelEditTitle}>
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
              ) : (
                <HStack 
                  flex={1} 
                  spacing={2}
                  onMouseEnter={handleMouseEnterTitle}
                  onMouseLeave={handleMouseLeaveTitle}
                >
                  <Text fontSize="lg" fontWeight="bold">
                    {formatTitleWithDate(stateTitle) || metadata?.subject || 'Untitled'}
                  </Text>
                  {isOpen && type === 'call' && (
                    <IconButton
                      aria-label="Edit Title"
                      icon={<FaEdit />}
                      size="sm"
                      onClick={handleEditTitle}
                      variant="ghost"
                      style={{
                        opacity: isHoveredTitle ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out'
                      }}
                    />
                  )}
                </HStack>
              )}
              <IconButton
                aria-label="Expand"
                icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                variant="ghost"
                onClick={onToggle}
              />
            </HStack>

            <HStack spacing={4} color="gray.600" fontSize="sm">
              <HStack spacing={1}>
                <TimeIcon boxSize={3} />
                <Text>{formatDate(metadata?.date || subtitle)}</Text>
              </HStack>
              {stateClient?.length > 0 && (
                <HStack spacing={1}>
                  <Icon as={FaUser} boxSize={3} />
                  {stateClient.map((c, index) => (
                    <Link 
                      key={index}
                      as={RouterLink} 
                      to={`/clients/${c.id}`}
                      color="#00417D"
                      _hover={{
                        textDecoration: 'underline',
                        color: '#00345B'
                      }}
                    >
                      <Text>
                        {`${c.firstName} ${c.lastName}${index < stateClient.length - 1 ? ',' : ''}`}
                      </Text>
                    </Link>
                  ))}
                </HStack>
              )}
            </HStack>
          </VStack>
        </HStack>

        <Box pl={12}>
          <OrderedList spacing={4}>
            {stateItems.map((item, index) => (
              <ListItem key={index}>
                <Text whiteSpace="pre-wrap">{item}</Text>
              </ListItem>
            ))}
          </OrderedList>
        </Box>
      </VStack>

      {/* LEAVE THE ERROR TOOLTIP COMMENTED OUT FOR PRODUCTION. */}
      {/* <HStack position="absolute" top={2} right={2} spacing={2}>
        {errorItems.length > 0 && (
          <Box position="relative">
            <IconButton
              aria-label="Error Info"
              icon={<FaInfo color="red" />}
              variant="ghost"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => e.stopPropagation()}
              top={2}
              right={12}
            />
            {showErrorTooltip && (
              <Box
                position="absolute"
                bg="gray.200"
                p={2}
                borderRadius="md"
                boxShadow="md"
                zIndex={1}
                top="100%"
                left="-450px"
                width="500px"
              >
                {errorItems.map((data, index) => (
                  <Box key={index} mt={5}>
                    <Text color="red.500">Action: {data.description}</Text>
                    <Text color="red.500">Error: {data.aiCompletionMessage}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      </HStack> */}

      <Collapse in={isOpen} animateOpacity>
        <HStack spacing={2} my={4}>
          <Button bg="#9DB4CA" onClick={type === 'email' ? handleViewEmail : handleViewTranscript}>
            <Icon as={FaUser} mr={2} />
            {type === 'email' ? 'View Email' : 'View Transcript'}
          </Button>
          <Button bg="#9DB4CA" onClick={() => {
            toast({
              title: "Archiving",
              description: `Archiving ${stateClient?.name || 'user'}'s correspondence...`,
              status: "info",
              duration: 1000,
              isClosable: true,
            });

            if (type === 'call' && correspondence?.archived) {
              handleUnarchiveCall(id);
            } else if (type === 'email' && correspondence?.archived) {
              handleUnarchiveEmail(id);
            } else if (type === 'call' && !correspondence?.archived) {
              handleArchiveCall(id);
            } else if (type === 'email' && !correspondence?.archived) {
              handleArchiveEmail(id);
            }
            onArchive(id);
          }}>
            {correspondence?.archived ? <ViewOffIcon mr={2} /> : <ViewIcon mr={2} />}
            {correspondence?.archived ? 'Unarchive' : 'Archive'}
          </Button>
          <Tooltip 
            label={!stateClient?.length ? "Please tag a client before creating a follow-up" : ""}
            isDisabled={stateClient?.length > 0}
          >
            <Button 
              bg="#9DB4CA" 
              onClick={handleFollowUp}
              isDisabled={!stateClient?.length}
              _disabled={{
                bg: 'gray.300',
                cursor: 'not-allowed',
                opacity: 0.6
              }}
            >
              <Icon as={FaRegEnvelope} mr={2} />
              Follow Up
            </Button>
          </Tooltip>
          {client.length > 0 && (
            <Button 
              bg="#9DB4CA" 
              onClick={handleRetagClient}
              isDisabled={!(stateCorrespondence.correspondenceType === 'call')}
              _disabled={{
                bg: 'gray.300',
                cursor: 'not-allowed',
                opacity: 0.6
              }}
            >
              <Icon as={FaSync} mr={2} />
              Retag Client
            </Button>
          )}
        </HStack>

        {(stateCorrespondence.correspondenceType === 'call' && (client.length === 0 || showClientSelection)) && (
          <Box 
            bg="white" 
            p={6} 
            borderRadius="xl" 
            mb={4}
            border="1px dashed"
            borderColor="gray.200"
            transition="all 0.2s"
            _hover={{ borderColor: "blue.200" }}
          >
            <VStack spacing={4} align="stretch">
              <HStack spacing={3}>
                <Icon as={FaUser} color="blue.500" />
                <Text fontWeight="medium">Assign a Client</Text>
              </HStack>
              
              <Input
                placeholder="Search for a client..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                size="lg"
                borderRadius="md"
                _focus={{
                  borderColor: "blue.400",
                  boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)"
                }}
              />
              
              {clientsLoading ? (
                <Text color="gray.500">Loading clients...</Text>
              ) : (
                <VStack align="stretch" spacing={2}>
                  {clients
                    .filter(orgClient =>
                      `${orgClient.firstName} ${orgClient.lastName}`
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .sort((a, b) =>
                      `${a.firstName} ${a.lastName}`
                        .localeCompare(`${b.firstName} ${b.lastName}`)
                    )
                    .slice(0, 5)
                    .map((orgClient) => (
                      <Button
                        key={orgClient.id}
                        variant="ghost"
                        justifyContent="flex-start"
                        p={4}
                        onClick={() => {
                          handleClientSelect(orgClient.id);
                          setShowClientSelection(false);
                        }}
                        _hover={{ bg: "blue.50" }}
                        leftIcon={<Icon as={FaUser} color="gray.400" />}
                      >
                        {`${orgClient.firstName} ${orgClient.lastName}`}
                      </Button>
                    ))}
                </VStack>
              )}
            </VStack>
          </Box>
        )}

        <Box 
          opacity={!stateClient?.length ? 0.6 : 1}
          filter={!stateClient?.length ? "grayscale(1)" : "none"}
          pointerEvents={!stateClient?.length ? "none" : "auto"}
        >
          <BlueBox
            actions={stateRows.map(item => ({
              ...item,
              content: (
                <HStack justify="space-between" w="100%">
                  <Text>{item.description}</Text>
                  <Menu>
                    <MenuButton 
                      as={Button} 
                      rightIcon={<ChevronDownIcon />} 
                      size="sm" 
                      variant="ghost"
                      isDisabled={!stateClient?.length}
                    >
                      Assign To
                    </MenuButton>
                    <MenuList>
                      <MenuItem>John Bohlmann</MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              )
            }))}
            transcriptId={id}
            client={stateClient}
            id={id}
            type={type}
            onArchive={(itemId) => {
              onArchive(itemId);
              onClose();
            }}
            scrollRef={scrollRef}
            correspondence={stateCorrespondence}
            isDisabled={!stateClient?.length}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

GrayBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  metadata: PropTypes.shape({
    subject: PropTypes.string,
    date: PropTypes.string,
    clientNames: PropTypes.string,
  }),
  listItems: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.array,
  transcriptId: PropTypes.string.isRequired,
  client: PropTypes.array,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['call', 'email']).isRequired,
  scrollRef: PropTypes.shape({
    current: PropTypes.any
  }),
  onArchive: PropTypes.func.isRequired,
  errorItems: PropTypes.array,
  correspondence: PropTypes.object,
  nonClientSpeakers: PropTypes.array
};

export default GrayBox;